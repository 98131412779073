<template>
  <div>
    <h4 id="dropdownLegend">Com quem este ator pode se associar/relacionar?</h4>
    <div class="dropdown">
      <input
        type="text"
        class="form-control w-100"
        name="searchName"
        placeholder="Digite aqui o nome dos atores ou organizações"
        @focus="focused = true"
        @blur="handleBlur"
        @change="changeHandler"
        @keyup="keyupHandler"
        v-model="inputText"
        autocomplete="off"
      >
      <template v-if="currentRouteName === 'Atores.Cadastro' || currentRouteName === 'Atores.Edicao'">
      <!-- COMBOBOX -->
      <ul class="dropdown-menu w-100" :class="{ 'show': focused && suggestionsList.length }" aria-labelledby="dropdownLegend">
        <li v-for="item of suggestionsList" :key="item.id" @click="handleSelected(item), selectedUser = {nome_do_ator: item.nome_do_ator, foto_perfil: item.foto_perfil}">
          <button type="button" class="dropdown-item">
            {{ item.id }} - {{ item.nome_do_ator }}
          </button>
        </li>
      </ul>
      <!-- FIM COMBOBOX -->
      </template>
      <template v-if="currentRouteName === 'PlanosDeAcao.Cadastro' || currentRouteName === 'PlanosDeAcao.Edicao' || currentRouteName === 'Parcerias.Cadastro' || currentRouteName === 'Parcerias.Edicao'">
      <ul class="dropdown-menu w-100" :class="{ 'show': focused && suggestionsList.length }" aria-labelledby="dropdownLegend">
        <li v-for="item of suggestionsList" :key="item.id" @click="handleSelected(item), selectedUser = {nome_do_ator: item.nome_do_ator, foto_perfil: item.foto_perfil}">
          <button type="button" class="dropdown-item" @click="handleBadgeClick(item), selectedUser = {nome_do_ator: item.nome_do_ator, foto_perfil: item.foto_perfil}">
            {{ item.id }} - {{ item.nome_do_ator }}
          </button>
        </li>
      </ul>
      </template>
    </div>
    <div class="suggestions">
      <template v-if="currentRouteName === 'Atores.Cadastro' || currentRouteName === 'Atores.Edicao'">
        <div v-for="actor of actorSelected" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1" :class="{ 'clickable' : withCallback }" @click="selectedActor(actor), showModal = true, selectedUser = {nome_do_ator: actor.nome_do_ator, foto_perfil: actor.foto_perfil} ">
          {{ actor.nome_do_ator }}
          <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor)">X</button>
        </div>
      </template>
      <template v-if="currentRouteName === 'PlanosDeAcao.Cadastro' || currentRouteName === 'PlanosDeAcao.Edicao' || currentRouteName ==='Parcerias.Cadastro' || currentRouteName === 'Parcerias.Edicao'">
        <div v-for="actor of actorSelected" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1" :class="{ 'clickable' : withCallback }">
          {{ actor.nome_do_ator }}
        <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor)">X</button>
      </div>
      </template>
      <template v-if="registry !== null && (typeof registry.lista_de_parceiros !== 'undefined')">
        <div v-for="actor of registry.lista_de_parceiros" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1" :class="{ 'clickable' : withCallback }">
          {{ actor.nome_do_ator }}
          <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor)">X</button>
        </div>
      </template>
       <template v-if="registry !== null &&  (typeof registry.lista_de_envolvidos !== 'undefined')">
        <div v-for="actor of registry.lista_de_envolvidos" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1" :class="{ 'clickable' : withCallback }">
          {{ actor.nome_do_ator }}
          <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor)">X</button>
        </div>
      </template>
      <template v-if="registry !== null && (typeof registry.lista_atores_tipo !== 'undefined')">
        <div v-for="actor of registry.lista_atores_tipo" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1" :class="{ 'clickable' : withCallback }">
          {{ actor.nome_do_ator }}
          <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor)">X</button>
        </div>
      </template>
      <template v-if="registry !== null && (typeof registry.lista_atores_tipo_para !== 'undefined')">
        <div v-for="actor of registry.lista_atores_tipo_para" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1" :class="{ 'clickable' : withCallback }">
          {{ actor.nome_do_ator }}
          <button type="button" class="close p-1 text-white ms-1 align-middle" @click="handleRemove($event, actor)">X</button>
        </div>
      </template>
    </div>
  </div>
  <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModal" @close="showModal = false">
      <modal>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-bottom: 40px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <h3 style="padding: 0 3em;">Você gostaria de se associar a {{selectedUser.nome_do_ator}}</h3>
        <div class="container_box">
          <div class="d-flex justify-content-center">
            <img :src="selectedUser.foto_perfil" alt="" width="128" height="128" class="foto-perfil-assoc"/>
          </div>
          <div class="d-flex justify-content-center">
            <p class="">{{selectedUser.nome_do_ator}}</p>
          </div>
          <div class="col-12 mb-4 mt-4">
            <label for="ligacao" class="form-label fw-bold">Qual o tipo de ligação desejada?</label>
            <div class="d-flex">
              <select id="ligacao" name="ligacao" class="form-select w-100" aria-label="Ligação desejada" v-model="tipoLigacao">
                <option v-for="associacao of associacoesAll" :key="associacao.id" :value="associacao.id" name="tipoLigacao">{{associacao.name}}</option>
              </select>
            </div>
          </div>
          <!-- <div class="col-12 mb-4 mt-4">
            <label class="form-label fw-bold">Parcerias?</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="parcerias" id="inputparcerias" value="Sim" checked>
              <label class="form-check-label" for="inputparcerias">
                Sim
              </label>
            </div>
          </div>
          <div class="form-check">
              <input class="form-check-input" type="radio" name="parcerias" id="inputparcerias2" value="Não">
              <label class="form-check-label" for="inputparcerias2">
                Não
              </label>
            </div>
          </div>
          <template v-if="currentRouteName === 'Parcerias.Cadastro' || currentRouteName === 'Parcerias.Edicao'">
          <div class="col-12 mb-4 mt-4">
            <label for="action" class="form-label fw-bold">Qual o nome da ação?</label>
            <div class="d-flex justify-content-between">
              <input type="text" class="form-control w-100" id="action" name="action" placeholder="Digite aqui o nome da ação"/>
            </div>
          </div>
          </template>-->

          <div class="d-flex justify-content-between pb-5 pt-3">
            <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModal = false">
              Voltar para o cadastro
            </button>
            <button class="btn btn-primary px-4 py-2 rounded-pill"  @click="handleBadgeClick(), showModal = false">
              Associar Ator
            </button>
          </div>
        </div>
      </modal>
  </div>
</template>

<script>
/*eslint-disable */
import { AssociacoesRepository, AtoresRepository, GetTipoAssociacao } from '../libs/repositories';
import { debounce } from '../libs/utils/index.js';
export default {
  name: 'ActorSearch',
  props: {
    badgeClickCallback: {
      type: Function,
      required: false,
      default: null
    },
    registry: {
      type: Object,
      required: false,
      default: null
    }
  },
  beforeCreate: async function () {
    const result = await GetTipoAssociacao.getAll();
    this.associacoesAll = result;
  },
  data () {
    return {
      withCallback: (typeof this.badgeClickCallback === 'function'),
      inputText: '',
      suggestionsList: [],
      actorSelected: [],
      /** @type {Set<number>} */
      actorIdsSelected: new Set(),
      focused: false,
      lastSearchedValue: '',
      showModal: false,
      selectedUser: null,
      teste: 'teste',
      associacoesAll: [],
      result: [],
      tipoLigacao: null,
      actor: null,
      actorRegistry: []
    };
  },
  methods: {
    /**
     * a variável `self` irá conter uma referência ao elemento vue instanciado
     */
    manageDataRequest: debounce(async (self, nameHint) => {
      if (nameHint.length < 2) {
        self.actorIdsSelected.clear();
        self.suggestionsList = [];
        return;
      };
      if (self.lastSearchedValue === nameHint) return;

      self.lastSearchedValue = nameHint;

      const response = await AtoresRepository.searchByName({ nameHint, excludedIds: [...self.actorIdsSelected] });

      if (response.error) {
        // eslint-disable-next-line
        console.trace(response.error);
      } else {
        self.suggestionsList = response.data;
      }
    }, 520), // 50ms foi o tempo considerado seguro para o fluxo de digitação
    changeHandler (event) {
      this.manageDataRequest(event.target.value);
    },
    keyupHandler (event) {
      this.manageDataRequest(event.target.value);
    },
    handleBlur () {
      window.setTimeout(() => {
        this.focused = false;
      }, 100);
    },
    handleSelected (actor) {
      if (!this.actorIdsSelected.has(actor.id)) {
        this.actorIdsSelected.add(actor.id);
        this.actorSelected = [...this.actorSelected, actor];
        this.suggestionsList = this.suggestionsList.filter((entry) => entry.id !== actor.id);
        this.$emit("actorIdsSelected", this.actorIdsSelected);
      }
    },
    async handleRemove ($event, actor) {
      /**
       * Para a propagação para evitar que o handler do pai do button também seja acionado
       */

      if (this.$route.name === 'Atores.Edicao') {
        const syncReponse = await AssociacoesRepository.sync({
          deAtorId: this.registry.id,
          desfazerId: [actor.id],
          desfazerTipoId: [actor.tipo_id]
        });
        // console.log(syncReponse);
        this.reloadPage();
      } else if (this.$route.name === 'PlanosDeAcao.Edicao') {
        const syncReponse = await AtoresRepository.removeActorByPlano({
          atorId: actor.ator_id,
          planoId: this.registry.id
        });
        // console.log(syncReponse);
        this.reloadPage();
      } else if (this.$route.name === 'Parcerias.Edicao') {
        const syncReponse = await AtoresRepository.removeActorByParceria({
          atorId: actor.ator_id,
          parceriaId: this.registry
        });
        // console.log(syncReponse);
        this.reloadPage();
      }
      $event.stopPropagation();

      if (this.actorIdsSelected.has(actor.id)) {
        this.actorIdsSelected.delete(actor.id);
        this.actorSelected = this.actorSelected.filter(entry => entry.id !== actor.id);
      }
    },
    selectedActor (actor) {
      this.actor = actor;
    },
    handleBadgeClick (actor) {
      if (typeof this.badgeClickCallback === 'function') {
        this.badgeClickCallback((this.actor === null ? actor : this.actor), this.tipoLigacao);
      }
    },
    reloadPage () {
      window.location.reload();
    }
  },
  mounted(){
    console.log('this.registry: ', this.registry);
  },
  computed: {
    currentRouteName () {
      return this.$route.name;
    }
  }
};
</script>

<style scoped>
.bg-secondary.rounded-pill {
  min-width: fit-content;
  max-width: fit-content;
  float:left;
}
.close {
  background-color: transparent;
  border: none;
  font-family: monospace;
  font-size: 12px;
}
.clickable {
  cursor: pointer;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
modal {
  background-color: white;
  display: block;
  max-width: 624px;
  border-radius: 5px;
}
h3{
  font-size: 23px;
}
.container_box{
  width: 360px;
  margin: 0 auto;
}
.foto-perfil-assoc{
  width:128px;
  height:128px;
  border-radius: 128px;
}
</style>
